// Generated by Framer (4f5e97b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {opt94Lqyo: {hover: true}, SCV0HHKWk: {hover: true}};

const cycleOrder = ["SCV0HHKWk", "opt94Lqyo"];

const serializationHash = "framer-AVW3O"

const variantClassNames = {opt94Lqyo: "framer-v-1n5m9vr", SCV0HHKWk: "framer-v-rcje2w"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {onDark: "opt94Lqyo", onLight: "SCV0HHKWk"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "SCV0HHKWk"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "SCV0HHKWk", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-AVW3O", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={"https://www.linkedin.com/company/whiteuistore"} openInNewTab><Image {...restProps} as={"a"} background={{alt: "LinkedIn", fit: "fit", intrinsicHeight: 64, intrinsicWidth: 64, pixelHeight: 64, pixelWidth: 64, src: "https://framerusercontent.com/images/S2nzl1oq7Pz2RRJm2zAIMn9rDAg.svg"}} className={`${cx("framer-rcje2w", className)} framer-1bfjnca`} data-framer-name={"onLight"} layoutDependency={layoutDependency} layoutId={"SCV0HHKWk"} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{"opt94Lqyo-hover": {opacity: 0.8}, "SCV0HHKWk-hover": {opacity: 0.8}}} {...addPropertyOverrides({"opt94Lqyo-hover": {"data-framer-name": undefined}, "SCV0HHKWk-hover": {"data-framer-name": undefined}, opt94Lqyo: {"data-framer-name": "onDark", background: {alt: "LinkedIn", fit: "fit", intrinsicHeight: 96, intrinsicWidth: 96, pixelHeight: 96, pixelWidth: 96, src: "https://framerusercontent.com/images/0PXxxJqu1aCsi0SKClNLoBYONAI.png"}}}, baseVariant, gestureVariant)}/></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-AVW3O [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-AVW3O .framer-1bfjnca { display: block; }", ".framer-AVW3O .framer-rcje2w { cursor: pointer; height: 32px; overflow: hidden; position: relative; text-decoration: none; width: 32px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"opt94Lqyo":{"layout":["fixed","fixed"]},"TFfeRso9u":{"layout":["fixed","fixed"]},"uR_wZxIHt":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramerfZJuerqnn: React.ComponentType<Props> = withCSS(Component, css, "framer-AVW3O") as typeof Component;
export default FramerfZJuerqnn;

FramerfZJuerqnn.displayName = "Social Icons / LinkedIn";

FramerfZJuerqnn.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerfZJuerqnn, {variant: {options: ["SCV0HHKWk", "opt94Lqyo"], optionTitles: ["onLight", "onDark"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerfZJuerqnn, [])